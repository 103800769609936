import DetailCard from "../../components/DetailCard/DetailCard";
import BackButton from "../../components/BackButton";

const PublicationPage = () => {
	return (
		// style={{display: 'flex', justifyContent: 'center', border: '1px solid black'}}
		<div style={{ width: "75%", textAlign: "left" }}>
			<BackButton />
			<h4 style={{ color: "#596274" }}>LITERATURE</h4>
			<DetailCard category={"paper"} />
		</div>
	);
};
export default PublicationPage;
