import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";

import DetailPage from "./DetailPage";

const TutorialPage = () => {
	const field_icon = {
		instructor: <RecordVoiceOverOutlinedIcon className="detail-icon-" style={{ color: "green" }} />,
		price: <AccountBalanceWalletOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />,
		published_date: (
			<>
				<CalendarMonthOutlinedIcon className="detail-icon-" />
				<span>Published Date:&nbsp;</span>
			</>
		),
		last_updated_date: (
			<>
				<CalendarMonthOutlinedIcon className="detail-icon-" />
				<span>Updated Date:&nbsp;</span>
			</>
		)
	};

	const field_heading = {
		overview: "Overview",
		course_content: "Course Content",
		reqirements: "Requirements",
		description: "Description"
	};

	return <DetailPage category={"tutorial"} heading={"title"} field_icon={field_icon} externalLink={{ field: "tutorial_link", text: "View Tutorial" }} field_heading={field_heading} />;
};
export default TutorialPage;
