import "./Card.css";
import Switch from "@mui/material/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { Link } from "react-router-dom";

const Card = ({ category, details, verticalCreator, updateNumResults, cardHeader, icons, urlIcons, dotColor, vertical_id, depth = 0, sublist = false, query_id }) => {
	const label = { inputProps: { "aria-label": "Switch demo" } };
	let clickable = true;
	let people;
	let type;
	if (cardHeader === "LITERATURE" || cardHeader == "PATENT") {
		const possibleKeys = ["authors", "inventors"];
		for (let key of possibleKeys) {
			if (key in details) {
				people = details[key].map((person) => person.name).join(", ");
				type = key;
				break;
			}
		}
	}

	let cardHeader_ = cardHeader;
	if (cardHeader == "GRANT") {
		details.title = details.OpportunityTitle;

		if (details.AwardCeiling) {
			details.award_value = details.AwardCeiling;
		} else if (details.AwardFloor) {
			details.award_value = details.AwardFloor;
		}

		cardHeader_ = details.grant_types.toUpperCase();
	}

	if (category == "people") {
		if (details?.type) {
			cardHeader_ = details.type.toUpperCase();
		}
	}

	const [show, setShow] = useState(details.show);
	const navigate = useNavigate();

	let userType = "u";
	if (verticalCreator) userType = "ve";

	let urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/";
	if (query_id != undefined) urlPrefix = "/" + userType + "/vertical/" + vertical_id + "/query/" + query_id + "/";

	if (depth === 1 && ((cardHeader === "LITERATURE" && !details?.openAccessPdf?.url && !details?.url) || (cardHeader === "PATENT" && !details?.patent_id))) {
		clickable = false;
	}

	function openDetailView() {
		if (depth === 0) {
			if (cardHeader_ === "AUTHOR") {
				navigate(urlPrefix + "author" + "/" + details.authorId);
			} else if (cardHeader_ === "INVENTOR") {
				navigate(urlPrefix + "inventor" + "/" + details.name);
			} else if (cardHeader_ === "LITERATURE") {
				navigate(urlPrefix + "papers" + "/" + details.paperId);
			} else if (cardHeader_ === "PATENT") {
				navigate(urlPrefix + "patents" + "/" + details.publication_number);
			} else {
				navigate(urlPrefix + category + "/" + details._id);
			}
		} else {
			if (cardHeader_ === "AUTHOR") {
				navigate(urlPrefix + "author" + "/" + details.authorId);
			} else if (cardHeader_ === "INVENTOR") {
				navigate(urlPrefix + "inventor" + "/" + details.name);
			} else if (cardHeader_ === "LITERATURE") {
				if (details?.openAccessPdf?.url) {
					window.open(details.openAccessPdf.url, "_blank");
				} else if (details?.url) {
					window.open(details.url, "_blank");
				}
			} else if (cardHeader_ === "PATENT") {
				if (details?.patent_id) {
					window.open(`https://patents.google.com/${details.patent_id}`, "_blank");
				}
			}
		}
	}

	function editContent(event) {
		event.stopPropagation();

		if (cardHeader_ === "AUTHOR") {
			navigate("edit/author/" + details.authorId);
		} else if (cardHeader_ === "INVENTOR") {
			navigate("edit/inventor/" + details.name);
		} else if (cardHeader_ === "LITERATURE") {
			navigate("edit/" + details.paperId);
		} else if (cardHeader_ === "PATENT") {
			navigate("edit/" + details.publication_number);
		} else {
			navigate("edit/" + details._id);
		}
	}

	const hideContent = async (event) => {
		event.stopPropagation();

		let category_ = category;
		if (cardHeader_ === "AUTHOR") {
			category_ = "authors";
		} else if (cardHeader_ === "INVENTOR") {
			category_ = "inventors";
		}

		const isChecked = event.target.checked;
		setShow(isChecked);

		if (!show) {
			updateNumResults("increment");
		} else {
			updateNumResults("decrement");
		}

		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "change_visibility?id=" + details._id + "&collection=" + category_, {
			method: "PATCH",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify({ show: isChecked })
		});
		await res.json();
	};

	return (
		<div className={clickable ? "card" : "card-nonclickable"} onClick={() => openDetailView()}>
			<div className="header" style={{ margin: 0, padding: 0 }}>
				<span className="dot" style={{ backgroundColor: dotColor }}></span>
				<span className="header-text">{cardHeader_}</span>
			</div>

			<div>
				{category === "people" || cardHeader === "ORGANIZATION" ? (
					<div style={{ display: "flex", alignItems: "center", marginBottom: "20px", marginTop: "20px" }}>
						{category === "people" ? (
							details.image_url ? (
								<img src={details.image_url} alt="Profile" style={{ height: "64px", width: "64px", marginRight: "20px", objectFit: "cover", borderRadius: "50%" }} />
							) : (
								<AccountCircleOutlinedIcon style={{ height: "64px", width: "64px", paddingRight: "20px" }} />
							)
						) : (
							cardHeader === "ORGANIZATION" &&
							(details.image_url ? (
								<img src={details.image_url} alt="Organization" style={{ height: "64px", width: "64px", marginRight: "20px", objectFit: "cover" }} />
							) : (
								<AccountBalanceOutlinedIcon style={{ height: "64px", width: "64px", paddingRight: "20px" }} />
							))
						)}

						{details.name && <h2 className="title">{details.name}</h2>}
					</div>
				) : details.title || details.OpportunityTitle ? (
					<h2 className="title">{details.title}</h2>
				) : (
					details.name && <h2 className="title">{details.name}</h2>
				)}

				<div>
					{details?.inventors?.length > 0 && (
						<div className="detail-item" style={{ color: "green" }}>
							{icons[type]}
							<div className="people">
								{details.inventors.map((inventor, index) => (
									<div key={inventor.name}>
										<Link to={`${urlPrefix}inventor/${inventor.name}`}>
											<span onClick={(e) => e.stopPropagation()}>{inventor.name}</span>
										</Link>
										{index < details.inventors.length - 1 && <span>,&nbsp;</span>}
									</div>
								))}
							</div>
						</div>
					)}

					{details?.authors?.length > 0 && (
						<div className="detail-item" style={{ color: "green" }}>
							{icons[type]}
							<div className="people">
								{details.authors.map((author, index) => (
									<div key={author.authorId}>
										{depth == 0 ? (
											<Link to={`${urlPrefix}author/${author.authorId}`}>
												<span onClick={(e) => e.stopPropagation()}>{author.name}</span>
											</Link>
										) : (
											<a href={`https://www.semanticscholar.org/author/${author.authorId}`} target="_blank">
												<span onClick={(e) => e.stopPropagation()}>{author.name}</span>
											</a>
										)}
										{index < details.authors.length - 1 && <span>,&nbsp;</span>}
									</div>
								))}
							</div>
						</div>
					)}

					<div>
						{Object.entries(details).map(
							([key, value]) =>
								key in icons &&
								key !== "authors" &&
								key !== "inventors" &&
								value && (
									<div key={key} className="detail-item">
										{icons[key]}
										{value}
									</div>
								)
						)}

						<div style={{ display: "flex", flexDirection: "row" }}>
							{urlIcons &&
								Object.entries(details).map(
									([key, value]) =>
										key in urlIcons &&
										value && (
											<a target="_blank" href={value}>
												{urlIcons[key]}
											</a>
										)
								)}
						</div>

						{details?.updated_at && <span className="footer">Last Updated On: {details.updated_at}</span>}

						{verticalCreator && depth === 0 && !sublist && (
							<div className="vertical-creator">
								<FontAwesomeIcon icon={faPenToSquare} className="detail-icon clickable" onClick={(event) => editContent(event)}></FontAwesomeIcon>
								<Switch {...label} checked={show} onClick={(event) => hideContent(event)} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
