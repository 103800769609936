import "./List.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import auth from "../../firebase";
import Card from "../Card/Card";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useTheme } from "@mui/material/styles";
import AddViaLink from "../AddViaLink";

const List = ({ category, heading, cardHeader, icons, urlIcons, dotColor, query }) => {
	const theme = useTheme();

	const { vertical_id, query_id } = useParams();
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [verticalCreator, setVerticalCreator] = useState(false);
	const [verticalEditor, setVerticalEditor] = useState(false);
	const [numResults, setNumResults] = useState();
	const [showAddViaLink, setShowAddViaLink] = useState(false);
	const [itemAdded, setItemAdded] = useState(0);
	const user = auth.currentUser;
	const navigate = useNavigate();

	const updateNumResults = (operation) => {
		setNumResults((prevNumResults) => (operation == "increment" ? prevNumResults + 1 : prevNumResults - 1));
	};

	function capitalizeFirstLetter(string) {
		if (!string) return string;
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	useEffect(() => {
		const getItems = async () => {
			if (category === "papers") {
				document.title = "Publications - The Simple Platform";
			} else if (category === "grants") {
				document.title = "Funding - The Simple Platform";
			} else {
				document.title = capitalizeFirstLetter(category) + " - The Simple Platform";
			}
			const items = await fetchItems();

			if (category !== "tutorials") {
				setVerticalCreator(items.verticalCreator);
				if (items.verticalCreator) {
					setItems(items.data);
				} else {
					setItems(items.data.filter((item) => item.show));
				}
			} else {
				setItems(items.data.filter((item) => item.show || item.creator));
				user.getIdTokenResult().then((idTokenResult) => {
					if (idTokenResult.claims.isVerticalEditor === true) {
						setVerticalEditor(true);
					}
				});
			}

			const countVisibleItems = items.data.filter((item) => item.show).length;
			setNumResults(countVisibleItems);
			setLoading(false);
		};
		getItems();
	}, [itemAdded]);

	const fetchItems = async () => {
		const token = await user.getIdToken();

		let id;
		if (query_id) {
			id = query_id;
		} else {
			id = vertical_id;
		}
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_" + category + "?vertical_id=" + id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	const addItem = async () => {
		if (category === "papers" || category === "patents" || category === "grants") {
			setShowAddViaLink((prevValue) => !prevValue);
		} else {
			navigate("add");
		}
	};

	const toggleAddForm = (newState) => {
		setShowAddViaLink(newState);
	};

	const displayNewItem = () => {
		setItemAdded((prevState) => prevState + 1);
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%" }}>
			<div className="papers">
				<div>
					<h2 style={{ marginBottom: 0, paddingBottom: 0 }}>
						{heading}
						{(verticalCreator || verticalEditor) && (
							<span>
								<FontAwesomeIcon icon={faCirclePlus} color={theme.palette.primary.main} className="add-button" onClick={() => addItem()}></FontAwesomeIcon>
							</span>
						)}
					</h2>

					<p style={{ marginTop: 0, paddingTop: 0, fontSize: "12px" }}>{numResults} results</p>

					{showAddViaLink && <AddViaLink category={category} toggleAddForm={toggleAddForm} displayNewItem={displayNewItem} />}
				</div>

				{items.length > 0 ? (
					<div>
						{items.map((item, index) =>
							category !== "tutorials" ? (
								<Card
									key={index}
									category={category}
									details={item}
									verticalCreator={verticalCreator}
									updateNumResults={updateNumResults}
									cardHeader={cardHeader}
									icons={icons}
									urlIcons={urlIcons}
									dotColor={dotColor}
									vertical_id={vertical_id}
									query_id={query_id}
								/>
							) : (
								<Card
									key={index}
									category={category}
									details={item}
									verticalCreator={item.creator}
									updateNumResults={updateNumResults}
									cardHeader={cardHeader}
									icons={icons}
									dotColor={dotColor}
									vertical_id={vertical_id}
									query_id={query_id}
								/>
							)
						)}
					</div>
				) : (
					<div className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<p>
							<i>No contents to display</i>
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default List;
