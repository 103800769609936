import List from "../../components/List/List";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";

const PeoplePage = () => {
	const icons = {
		affiliations: <AccountBalanceOutlinedIcon style={{ color: "#AB6EF9" }} className="detail-icon" />
	};

	const urlIcons = {
		homepage: <img src={`${process.env.PUBLIC_URL}/Personal website.svg`} alt="X logo" className="detail-icon clickable" />,
		resume: <img src={`${process.env.PUBLIC_URL}/cv 2.svg`} alt="resume" className="detail-icon clickable" />,
		LinkedIn: <img src={`${process.env.PUBLIC_URL}/LinkedIn.svg`} alt="LinkedIn logo" className="detail-icon clickable" />,
		ResearchGate: <img src={`${process.env.PUBLIC_URL}/ResearchGate.svg`} alt="ResearchGate logo" className="detail-icon clickable" />,
		GoogleScholar: <img src={`${process.env.PUBLIC_URL}/People's social media.svg`} alt="GoogleScholar logo" className="detail-icon clickable" />,
		ORCID: <img src={`${process.env.PUBLIC_URL}/ORCID.svg`} alt="ORCID logo" className="detail-icon clickable" />,
		X: <img src={`${process.env.PUBLIC_URL}/X.svg`} alt="X logo" className="detail-icon clickable" />,
		organization_url: <InsertLinkOutlinedIcon className="detail-icon clickable" />
	};

	return (
		<>
			<List category={"people"} heading={"People"} cardHeader={"PEOPLE"} icons={icons} urlIcons={urlIcons} dotColor={"green"} />
		</>
	);
};
export default PeoplePage;
