import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import DetailPage from "./DetailPage";

const JobPage = () => {
	const field_icon = {
		company: <BusinessOutlinedIcon className="detail-icon-" />,
		location: <LocationOnOutlinedIcon className="detail-icon-" style={{ color: "#FF6666" }} />,
		salary: <AccountBalanceWalletOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />,
		posting_date: (
			<>
				<CalendarMonthOutlinedIcon className="detail-icon-" />
				<span>Posting Date:&nbsp;</span>
			</>
		),
		workplace_type: <EmojiTransportationOutlinedIcon className="detail-icon-" />,
		job_type: <WorkHistoryOutlinedIcon className="detail-icon-" />,
		contact_information: <BadgeOutlinedIcon className="detail-icon-" />
	};

	const field_heading = {
		description: "Description",
		responsibilities: "Responsibilities",
		requirements: "Requirements",
		preferred_qualifications: "Preferred Qualifications",
		benifits: "Benifits",
		company_overview: "Company Overview",
		how_to_apply: "How To Apply"
	};

	return (
		<DetailPage
			category={"job"}
			heading={"title"}
			field_icon={field_icon}
			importantDate={{ field: "application_deadline", text: "Application Deadline" }}
			externalLink={{ field: "application_link", text: "Apply to Job" }}
			field_heading={field_heading}
		/>
	);
};
export default JobPage;
