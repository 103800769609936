import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddViaLink = ({ category, toggleAddForm, displayNewItem }) => {
	const notify = () => toast.success("Paper added successfully!");
	let placeholder = "";
	let urlPattern = "";
	let buttonText = "";
	let toastCategory = "";
	if (category === "papers") {
		placeholder = "Use URL from Semantic Scholar: https://www.semanticscholar.org/paper/Direct-Methane-Removal/2cdd33212";
		urlPattern = /semanticscholar\.org\/paper\/[^\/]+\/([^\/]+)/;
		buttonText = "Add Paper";
		toastCategory = "Paper";
	} else if (category === "patents") {
		placeholder = "Use URL from Google Patents: https://patents.google.com/patent/KR101304652B1/en?q=(methane)&oq=methane";
		urlPattern = /patents\.google\.com\/patent\/([^\/]+\/en)/;
		buttonText = "Add Patent";
		toastCategory = "Patent";
	} else if (category == "grants") {
		placeholder = "Use URL from grants.gov: https://grants.gov/search-results-detail/355386"; // OR Use URL from grantforward.com: https://www.grantforward.com/grant?grant_id=958833
		urlPattern = /grants\.gov\/search-results-detail\/(\d+)|grantforward\.com\/grant\?grant_id\=(\d+)/;
		buttonText = "Add Grant";
		toastCategory = "Grant";
	}

	const theme = useTheme();
	const [url, setUrl] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { vertical_id } = useParams();

	const buttonStyle = {
		textTransform: "none",
		margin: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		whiteSpace: "nowrap",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	const submitForm = async (e) => {
		e.preventDefault();

		const id = validateUrl(urlPattern, url);

		if (id) {
			setLoading(true);

			try {
				const response = await addItem(id);
				setLoading(false);
				if (response === 200) {
					toggleAddForm(false);
					displayNewItem();
					toast.success(toastCategory + " added successfully!", {
						className: "toast",
						autoClose: 3000
					});
				} else if (response === 400) {
					toast.error(toastCategory + " already added!", {
						className: "toast",
						autoClose: 3000
					});
				} else {
					toast.error("Unable to add " + category.slice(0, -1) + "!", {
						className: "toast",
						autoClose: 3000
					});
				}
			} catch (error) {
				setLoading(false);
			}
		} else {
			setIsValid(false);
		}
	};

	const validateUrl = (urlPattern, url) => {
		const match = url.match(urlPattern);
		if (match && match[1]) {
			return match[1];
		} else if (match && match[2]) {
			return match[2];
		} else {
			return null;
		}
	};

	const addItem = async (id) => {
		const body = {
			id: id,
			vertical_id: vertical_id
		};
		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "add_" + category.slice(0, -1), {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		});

		const data = await res.json();
		return res.status;
	};

	return (
		<>
			<form onSubmit={submitForm} style={{ display: "flex", alignItems: "center" }}>
				<input
					type="text"
					id="author"
					name="author"
					style={{ width: "95%", border: isValid ? "1px solid #e2e8f0" : "1px solid red", borderRadius: "0.375rem", padding: "0.5rem" }}
					placeholder={placeholder}
					required
					onChange={(e) => setUrl(e.target.value)}
				/>
				{!loading ? (
					<Button type="submit" variant="outlined" disableRipple={true} sx={buttonStyle}>
						{buttonText}
					</Button>
				) : (
					<FontAwesomeIcon icon={faSpinner} style={{ color: "black", width: "92px", padding: "5px 10px", margin: "10px" }} spin size="2x"></FontAwesomeIcon>
				)}
			</form>
			{!isValid && (
				<p style={{ color: "red", fontSize: "12px", textAlign: "left", margin: 0 }}>
					<FaTimes style={{ color: "red", verticalAlign: "middle" }} />
					&nbsp;&nbsp;Invalid URL
				</p>
			)}

			{category === "grants" && (
				<Button type="button" variant="outlined" disableRipple="true" sx={{ marginTop: "10px" }} onClick={() => navigate("add")}>
					Add Manually
				</Button>
			)}
		</>
	);
};
export default AddViaLink;
