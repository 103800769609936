import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import "./Sidebar.css";
import { colors } from "@mui/material";

const Sidebar = ({ verticalEditor }) => {
	const { vertical_id, query_id } = useParams();
	const location = useLocation();

	const menuItems = [
		{ icon: <HomeOutlinedIcon />, text: "Home" },
		{ icon: <BookOutlinedIcon />, text: "Publications", path: "paper" },
		{ icon: <MailLockOutlinedIcon />, text: "Patents" },
		{ icon: <AccountBalanceWalletOutlinedIcon />, text: "Funding", path: "grant" },
		{ icon: <PeopleOutlinedIcon />, text: "People", path: "author", path2: "inventor" },
		{ icon: <WorkOutlineOutlinedIcon />, text: "Jobs" },
		{ icon: <AccountBalanceOutlinedIcon />, text: "Organizations" },
		{ icon: <EventOutlinedIcon />, text: "Events" },
		{ icon: <PlayCircleOutlinedIcon />, text: "Tutorials" }
	];

	let userType;
	if (verticalEditor) {
		userType = "ve";
	} else {
		userType = "u";
	}

	return (
		<div className="sidebar">
			{menuItems.map((item, index) => (
				<NavLink
					key={index}
					to={query_id == undefined ? "/" + userType + `/vertical/${vertical_id}/${item.text.toLowerCase()}` : "/" + userType + `/vertical/${vertical_id}/query/${query_id}/${item.text.toLowerCase()}`}
					className={({ isActive }) => (isActive || location.pathname.includes(item.path) || location.pathname.includes(item.path2) ? "sidebar-item-active" : "sidebar-item")}>
					{item.icon}
					<span>{item.text}</span>
				</NavLink>
			))}
		</div>
	);
};

export default Sidebar;
