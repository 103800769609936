import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import auth from "../../firebase";
import { signOut } from "firebase/auth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Navbar1 = ({ verticalEditor }) => {
	const { vertical_id } = useParams();

	const navigate = useNavigate();
	const location = useLocation();
	const [openMenu, setOpenMenu] = useState(false);
	const handleMenu = () => {
		setOpenMenu(true);
	};

	const handleClose = () => {
		setOpenMenu(false);
	};

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				navigate("/login");
			})
			.catch((error) => {
				// An error happened.
			});
	};

	let navigateTo;
	let homePage;
	let profilePage;
	if (!verticalEditor) {
		navigateTo = location.pathname.split("/").slice(0, 4).join("/") + "/tryrnai";
		homePage = location.pathname.split("/").slice(0, 4).join("/");
		profilePage = location.pathname.split("/").slice(0, 4).join("/") + "/profile";
	} else {
		navigateTo = "/ve/tryrnai";
		homePage = "/ve/tryrnai";
		profilePage = "/ve/profile";
		// if (location.pathname === "/ve" || location.pathname === "/ve/tryrnai") {
		// 	profilePage = "/ve/profile";
		// } else {
		// 	profilePage = location.pathname.split("/").slice(0, 4).join("/") + "/profile";
		// }
	}

	const handleJoinSlack = () => {
		window.open("https://join.slack.com/t/tspthesimpleplatform/shared_invite/zt-2n9i04xuc-gXVKkrZ8woUDqUesKTOUXQ", "_blank");
	};

	let image, style;
	if (vertical_id === "66aba1714138ba6f29f54279") {
		image = "/methane.png";
		style = { height: "50px", margin: "0 20px" };

		// if (verticalEditor) {
		// 	homePage = location.pathname.split("/").slice(0, 4).join("/") + "/home";
		// }
		homePage = location.pathname.split("/").slice(0, 4).join("/") + "/home";
	} else {
		image = "/logo.svg";
		style = { height: "40px", margin: "0 10px" };
	}

	const theme = useTheme();

	const activeButtonStyle = {
		textTransform: "none",
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		"&:hover": {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main
		}
	};

	return (
		<>
			<div className="navbar">
				<div>
					{/* <h4 className='navbar-header' onClick={() => navigate(homePage)}>The Simple Platform</h4> */}
					<Link to={homePage}>
						<img src={`${process.env.PUBLIC_URL + image}`} alt="TSP logo" style={style} />
					</Link>
				</div>
				<div className="navbar-items">
					<a href="https://faq.businesstech.fr/en/product/0-generic-faqs" target="_blank">
						<FontAwesomeIcon icon={faInfoCircle} color="black" className="clickable"></FontAwesomeIcon>
					</a>
					<Button variant="outlined" sx={{ textTransform: "none" }} onClick={handleJoinSlack}>
						<img src={`${process.env.PUBLIC_URL}/slack.png`} alt="Slack logo" className="navbar-image" style={{ height: "20px" }} />
						Go To Community
					</Button>
					<Button variant="outlined" sx={location.pathname.includes("tryrnai") || location.pathname === "/ve" ? activeButtonStyle : { textTransform: "none" }} onClick={() => navigate(navigateTo)}>
						Try RNAI
					</Button>
					<IconButton size="large" onClick={handleMenu} color="inherit">
						<AccountCircle sx={{ fontSize: 30 }} />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorOrigin={{
							vertical: "top",
							horizontal: "right"
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right"
						}}
						open={openMenu}
						onClose={handleClose}>
						<MenuItem
							onClick={() => {
								navigate(profilePage);
								handleClose();
							}}>
							Profile
						</MenuItem>
						<MenuItem onClick={handleLogout}>Sign Out</MenuItem>
					</Menu>
				</div>
			</div>
			{location.pathname.includes("query") && (
				<div className="back" onClick={() => navigate(navigateTo)}>
					<ArrowBackIosIcon />
					<span>Back To My Queries</span>
				</div>
			)}
			{(location.pathname.includes("edit") || location.pathname.includes("add") || location.pathname.includes("/ve/profile")) && (
				<div className="back-button" onClick={() => navigate(-1)}>
					<ArrowBackIcon />
					<span>&nbsp;Back</span>
				</div>
			)}
		</>
	);
};
export default Navbar1;
