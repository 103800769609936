import FormWithImage from "../../components/Forms/FormWithImage";

const OrganizationForm = ({ functionality }) => {
	const category = "organization";
	const fields = [
		{ name: "name", label: "Name", type: "text", required: true },
		{ name: "department", label: "Department", type: "text", required: true },
		{ name: "location", label: "Location", type: "text", required: true },
		{ name: "website", label: "Wesbite", type: "url", required: true, placeholder: "https://usc.edu" },
		{ name: "overview", label: "Overview", type: "text", required: true, text_area: true },
		{ name: "mission_statement", label: "Mission Statement", type: "text", required: false, text_area: true },
		{ name: "image_url", label: "Logo", type: "image", required: false }
	];

	return <FormWithImage category={category} fields={fields} functionality={functionality} />;
};
export default OrganizationForm;
