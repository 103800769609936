import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import DetailPage from "./DetailPage";

const PersonPage = () => {
	const field_icon = {
		affiliations: <AccountBalanceOutlinedIcon className="detail-icon-" style={{ color: "#AB6EF9" }} />,
		department: <AccountTreeOutlinedIcon className="detail-icon-" />,
		program: <AccountTreeOutlinedIcon className="detail-icon-" />,
		email: <AlternateEmailOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />
	};

	const url_icon = {
		resume: <img src={`${process.env.PUBLIC_URL}/cv 2.svg`} alt="resume" className="detail-icon clickable" />,
		LinkedIn: <img src={`${process.env.PUBLIC_URL}/LinkedIn.svg`} alt="LinkedIn logo" className="detail-icon clickable" />,
		ResearchGate: <img src={`${process.env.PUBLIC_URL}/ResearchGate.svg`} alt="ResearchGate logo" className="detail-icon clickable" />,
		GoogleScholar: <img src={`${process.env.PUBLIC_URL}/People's social media.svg`} alt="GoogleScholar logo" className="detail-icon clickable" />,
		ORCID: <img src={`${process.env.PUBLIC_URL}/ORCID.svg`} alt="ORCID logo" className="detail-icon clickable" />,
		X: <img src={`${process.env.PUBLIC_URL}/X.svg`} alt="X logo" className="detail-icon clickable" />,
		organization_url: <img src={`${process.env.PUBLIC_URL}/Personal website.svg`} alt="X logo" className="detail-icon clickable" />
	};

	const field_heading = {
		about: "About"
	};

	return <DetailPage category={"person"} heading={"name"} field_icon={field_icon} externalLink={{ field: "homepage", text: "Vist Homepage" }} field_heading={field_heading} url_icon={url_icon} />;
};

export default PersonPage;
