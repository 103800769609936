import React, { useState } from "react";

const ImageUpload = ({ existingImageUrl, onFileSelect }) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(existingImageUrl || null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setSelectedFile(file);
			onFileSelect(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleRemoveFile = () => {
		setSelectedFile(null);
		onFileSelect("");
		setPreviewUrl(null);
	};

	return (
		<div style={{ textAlign: "center", marginTop: "20px" }}>
			<div
				style={{
					border: "2px dashed #ddd",
					borderRadius: "8px",
					padding: "20px",
					cursor: "pointer"
				}}
				onDragOver={(e) => e.preventDefault()}
				onDrop={(e) => {
					e.preventDefault();
					handleFileChange(e);
				}}>
				{!previewUrl ? (
					<div>
						<p>
							Drag and drop file to attach it or
							<label style={{ color: "blue", cursor: "pointer" }}>
								{" "}
								browse
								<input type="file" hidden accept=".png,.jpg" onChange={handleFileChange} />
							</label>
						</p>
						<p>Accept .png, .jpg</p>
					</div>
				) : (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<img src={previewUrl} alt="Preview" style={{ width: "64px", height: "64px", marginRight: "20px", borderRadius: "50%" }} />
						<div>
							{selectedFile ? (
								<>
									<p>{selectedFile.name}</p>
									<p>{(selectedFile.size / 1024).toFixed(1)} KB • Uploaded</p>
								</>
							) : (
								<>
									<p>{previewUrl.split("/").pop()}</p>
									{/* <p>{(selectedFile.size / 1024).toFixed(1)} KB • Uploaded</p> */}
								</>
							)}
						</div>
						<button onClick={handleRemoveFile} style={{ marginLeft: "20px", backgroundColor: "transparent", border: "none", cursor: "pointer" }}>
							❌
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ImageUpload;
