import "./MainLayout.css";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

const MainLayout = ({ verticalEditor = false }) => {
	const location = useLocation();
	let displaySidebar;

	if (verticalEditor) {
		if (
			location.pathname === "/ve" ||
			location.pathname === "/ve/profile" ||
			location.pathname === "/ve/tryrnai" ||
			location.pathname === "/ve/tryrnai/profile" ||
			location.pathname.includes("edit") ||
			location.pathname.includes("add") ||
			location.pathname === "/ve/createVerticalEditor/LDpJ2EydS8h1Sr5" ||
			location.pathname === "/ve/createUser/LDpJ2EydS8h1Sr5"
		) {
			displaySidebar = false;
		} else {
			displaySidebar = true;
		}
	} else {
		displaySidebar = true;
	}

	let nested_query = false;
	if (location.pathname.includes("query")) {
		nested_query = true;
	}

	return (
		<>
			<Navbar verticalEditor={verticalEditor} />
			{displaySidebar && <Sidebar verticalEditor={verticalEditor} />}
			<div className={displaySidebar ? "showSidebar" : "hideSidebar"} style={{ marginTop: nested_query && "90px" }}>
				<Outlet />
			</div>
		</>
	);
};
export default MainLayout;
