import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

import DetailPage from "./DetailPage";

const OrganizationPage = () => {
	const field_icon = {
		department: <AccountTreeOutlinedIcon className="detail-icon-" />,
		location: <LocationOnOutlinedIcon className="detail-icon-" style={{ color: "#FF6666" }} />
		// website: <LanguageOutlinedIcon className="detail-icon clickable" style={{ color: "#4C90F6" }} />
	};

	const field_heading = {
		overview: "Overview",
		mission_statement: "Mission Statement"
	};

	return <DetailPage category={"organization"} heading={"name"} field_icon={field_icon} externalLink={{ field: "website", text: "Vist Website" }} field_heading={field_heading} />;
};

export default OrganizationPage;
