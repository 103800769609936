import "./index.css";

import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";

import { AuthContext } from "./AuthContext";
import { Protected } from "./components/Protected";

import AuthLayout from "./layouts/AuthLayout";
import LoginPage from "./pages/LogInPage";

import MainLayout from "./layouts/MainLayout";

import TryRNAIPage from "./pages/TryRNAIPage";

import HomePage from "./pages/ListViews/HomePage";
import PublicationsPage from "./pages/ListViews/PublicationsPage";
import PatentsPage from "./pages/ListViews/PatentsPage";
import FundingPage from "./pages/ListViews/FundingPage";
import PeoplePage from "./pages/ListViews/PeoplePage";
import EventsPage from "./pages/ListViews/EventsPage";
import JobsPage from "./pages/ListViews/JobsPage";
import TutorialsPage from "./pages/ListViews/TutorialsPage";
import OrganizationsPage from "./pages/ListViews/OrganizationsPage";

import JobForm from "./pages/FormPages/JobForm";
import EventForm from "./pages/FormPages/EventForm";
import TutorialForm from "./pages/FormPages/TutorialForm";
import OrganizationForm from "./pages/FormPages/OrganizationForm";
import PublicationForm from "./pages/FormPages/PublicationForm";
import PatentForm from "./pages/FormPages/PatentForm";
import FundingForm from "./pages/FormPages/FundingForm";
import AuthorForm from "./pages/FormPages/AuthorForm";
import InventorForm from "./pages/FormPages/InventorForm";
import PersonForm from "./pages/FormPages/PersonForm";

import PublicationPage from "./pages/DetailViews/PublicationPage";
import PatentPage from "./pages/DetailViews/PatentPage";
import AuthorPage from "./pages/DetailViews/AuthorPage";
import InventorPage from "./pages/DetailViews/InventorPage";
import GrantPage from "./pages/DetailViews/GrantPage";
import EventPage from "./pages/DetailViews/EventPage";
import JobPage from "./pages/DetailViews/JobPage";
import OrganizationPage from "./pages/DetailViews/OrganizationPage";
import TutorialPage from "./pages/DetailViews/TutorialPage";
import PersonPage from "./pages/DetailViews/PersonPage";

import ProfilePage from "./pages/ProfilePage";

import CreateCredentialsPage from "./pages/CreateCredentialsPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { initializeHotjar } from "./hotjar";

function App() {
	initializeHotjar();

	const [email, setEmail] = useState("");
	const setEmail_ = (email) => {
		setEmail(email);
	};

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				{/* Vertical Editor Pages */}
				<Route
					path="/ve"
					element={
						<>
							<Protected adminOnly>
								<MainLayout verticalEditor={true} />
							</Protected>
						</>
					}>
					{/* Profile Page */}
					<Route path="profile" element={<ProfilePage />} />
					<Route path="tryrnai/profile" element={<ProfilePage />} />
					<Route path="vertical/:vertical_id/profile" element={<ProfilePage />} />

					{/* RNAI Pages */}
					<Route index element={<TryRNAIPage userRole="admin" />} />
					<Route path="tryrnai" element={<TryRNAIPage userRole="admin" />} />

					{/* List View Pages */}
					<Route path="vertical/:vertical_id/home" element={<HomePage />} />
					<Route path="vertical/:vertical_id/people" element={<PeoplePage />} />
					<Route path="vertical/:vertical_id/funding" element={<FundingPage />} />
					<Route path="vertical/:vertical_id/publications" element={<PublicationsPage />} />
					<Route path="vertical/:vertical_id/patents" element={<PatentsPage />} />
					<Route path="vertical/:vertical_id/organizations" element={<OrganizationsPage />} />
					<Route path="vertical/:vertical_id/events" element={<EventsPage />} />
					<Route path="vertical/:vertical_id/tutorials" element={<TutorialsPage />} />
					<Route path="vertical/:vertical_id/jobs" element={<JobsPage />} />

					{/* Detail View Pages */}
					<Route path="vertical/:vertical_id/author/:id" element={<AuthorPage />} />
					<Route path="vertical/:vertical_id/inventor/:id" element={<InventorPage />} />
					<Route path="vertical/:vertical_id/grants/:id" element={<GrantPage />} />
					<Route path="vertical/:vertical_id/papers/:id" element={<PublicationPage />} />
					<Route path="vertical/:vertical_id/patents/:id" element={<PatentPage />} />
					<Route path="vertical/:vertical_id/organizations/:id" element={<OrganizationPage />} />
					<Route path="vertical/:vertical_id/events/:id" element={<EventPage />} />
					<Route path="vertical/:vertical_id/tutorials/:id" element={<TutorialPage />} />
					<Route path="vertical/:vertical_id/jobs/:id" element={<JobPage />} />
					<Route path="vertical/:vertical_id/people/:id" element={<PersonPage />} />

					{/* Edit Pages */}

					<Route path="vertical/:vertical_id/funding/edit/:id" element={<FundingForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/grants/:id/edit" element={<FundingForm functionality={"edit"} />} />

					<Route path="vertical/:vertical_id/publications/edit/:id" element={<PublicationForm />} />
					<Route path="vertical/:vertical_id/papers/:id/edit/" element={<PublicationForm />} />

					<Route path="vertical/:vertical_id/patents/edit/:id" element={<PatentForm />} />
					<Route path="vertical/:vertical_id/patents/:id/edit/" element={<PatentForm />} />

					<Route path="vertical/:vertical_id/people/edit/author/:id" element={<AuthorForm />} />
					<Route path="vertical/:vertical_id/author/:id/edit" element={<AuthorForm />} />

					<Route path="vertical/:vertical_id/people/edit/inventor/:id" element={<InventorForm />} />
					<Route path="vertical/:vertical_id/inventor/:id/edit" element={<InventorForm />} />

					<Route path="vertical/:vertical_id/organizations/edit/:id" element={<OrganizationForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/organizations/:id/edit" element={<OrganizationForm functionality={"edit"} />} />

					<Route path="vertical/:vertical_id/events/edit/:id" element={<EventForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/events/:id/edit" element={<EventForm functionality={"edit"} />} />

					<Route path="vertical/:vertical_id/tutorials/edit/:id" element={<TutorialForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/tutorials/:id/edit" element={<TutorialForm functionality={"edit"} />} />

					<Route path="vertical/:vertical_id/jobs/edit/:id" element={<JobForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/jobs/:id/edit" element={<JobForm functionality={"edit"} />} />

					<Route path="vertical/:vertical_id/people/edit/:id" element={<PersonForm functionality={"edit"} />} />
					<Route path="vertical/:vertical_id/people/:id/edit" element={<PersonForm functionality={"edit"} />} />

					{/* Add Pages */}
					<Route path="vertical/:vertical_id/funding/add" element={<FundingForm functionality={"add"} />} />
					<Route path="vertical/:vertical_id/organizations/add" element={<OrganizationForm functionality={"add"} />} />
					<Route path="vertical/:vertical_id/events/add" element={<EventForm functionality={"add"} />} />
					<Route path="vertical/:vertical_id/tutorials/add" element={<TutorialForm functionality={"add"} />} />
					<Route path="vertical/:vertical_id/jobs/add" element={<JobForm functionality={"add"} />} />
					<Route path="vertical/:vertical_id/people/add" element={<PersonForm functionality={"add"} />} />

					{/* Account Creation Pages */}
					<Route path="createVerticalEditor/LDpJ2EydS8h1Sr5" element={<CreateCredentialsPage role={"Vertical Editor"} />} />
					<Route path="createUser/LDpJ2EydS8h1Sr5" element={<CreateCredentialsPage role={"User"} />} />
				</Route>

				{/* User Pages */}
				<Route
					path="/u/vertical/:vertical_id"
					element={
						<>
							<Protected>
								<MainLayout />
							</Protected>
						</>
					}>
					{/* Home Page */}
					<Route index element={<HomePage />} />

					{/* RNAI Page  */}
					<Route path="tryrnai" element={<TryRNAIPage userRole={"user"} />} />

					{/* List View Pages */}
					<Route path="home" element={<HomePage />} />
					<Route path="people" element={<PeoplePage />} />
					<Route path="funding" element={<FundingPage />} />
					<Route path="publications" element={<PublicationsPage />} />
					<Route path="patents" element={<PatentsPage />} />
					<Route path="organizations" element={<OrganizationsPage />} />
					<Route path="events" element={<EventsPage />} />
					<Route path="tutorials" element={<TutorialsPage />} />
					<Route path="jobs" element={<JobsPage />} />

					{/* Detail View Pages */}
					<Route path="author/:id" element={<AuthorPage />} />
					<Route path="inventor/:id" element={<InventorPage />} />
					<Route path="grants/:id" element={<GrantPage />} />
					<Route path="papers/:id" element={<PublicationPage />} />
					<Route path="patents/:id" element={<PatentPage />} />
					<Route path="organizations/:id" element={<OrganizationPage />} />
					<Route path="events/:id" element={<EventPage />} />
					<Route path="tutorials/:id" element={<TutorialPage />} />
					<Route path="jobs/:id" element={<JobPage />} />
					<Route path="people/:id" element={<PersonPage />} />

					{/* View Query List Pages */}
					<Route path="query/:query_id/home" element={<HomePage />} />
					<Route path="query/:query_id/people" element={<PeoplePage />} />
					<Route path="query/:query_id/funding" element={<FundingPage />} />
					<Route path="query/:query_id/publications" element={<PublicationsPage />} />
					<Route path="query/:query_id/patents" element={<PatentsPage />} />
					<Route path="query/:query_id/organizations" element={<OrganizationsPage />} />
					<Route path="query/:query_id/events" element={<EventsPage />} />
					<Route path="query/:query_id/tutorials" element={<TutorialsPage />} />
					<Route path="query/:query_id/jobs" element={<JobsPage />} />

					{/* View Query Detail Pages */}
					<Route path="query/:query_id/author/:id" element={<AuthorPage />} />
					<Route path="query/:query_id/inventor/:id" element={<InventorPage />} />
					<Route path="query/:query_id/grants/:id" element={<GrantPage />} />
					<Route path="query/:query_id/papers/:id" element={<PublicationPage />} />
					<Route path="query/:query_id/patents/:id" element={<PatentPage />} />
					<Route path="query/:query_id/organizations/:id" element={<OrganizationPage />} />
					<Route path="query/:query_id/events/:id" element={<EventPage />} />
					<Route path="query/:query_id/tutorials/:id" element={<TutorialPage />} />
					<Route path="query/:query_id/jobs/:id" element={<JobPage />} />
					<Route path="query/:query_id/people/:id" element={<PersonPage />} />

					{/* Profile Page */}
					<Route path="profile" element={<ProfilePage />} />
				</Route>

				<Route path="/" element={<AuthLayout />}>
					<Route index element={<LoginPage setEmail={setEmail} />} />
					<Route path="login" element={<LoginPage setEmail={setEmail} />} />
				</Route>
			</>
		)
	);

	return <RouterProvider router={router} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<AuthContext>
		<App />
		<ToastContainer />
	</AuthContext>
	// </React.StrictMode>
);
