// import TutorialList from "../components/List/TutorialList"
import List from "../../components/List/List";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";

const TutorialsPage = () => {
	const icons = {
		instructor: <RecordVoiceOverOutlinedIcon style={{ color: "green" }} className="detail-icon" />,
		price: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />,
		published_date: <EventAvailableOutlinedIcon className="detail-icon" />
	};

	return <List category={"tutorials"} heading={"Tutorials"} cardHeader={"TUTORIAL"} icons={icons} dotColor={"pink"} />;
};

export default TutorialsPage;
