import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faGear } from "@fortawesome/free-solid-svg-icons";
import auth from "../firebase";

const Form = ({ navigateOnSubmit }) => {
	const user = auth.currentUser;
	const [autoSuggest, setAutoSuggest] = useState(false);
	const [genericNames, setGenericNames] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [numberOfGrantsPerGenericName, setNumberOfGrantsPerGenericName] = useState(3);
	const [numberOfPatents, setNumberOfPatents] = useState(10);

	const fetchGenericNames = async (verticalName) => {
		setLoading(true);
		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "getGenericNames?verticalName=" + verticalName);
		const data = await res.json();

		setGenericNames([
			{
				id: 1,
				genericName: data[0]
			},
			{
				id: 2,
				genericName: data[1]
			},
			{
				id: 3,
				genericName: data[2]
			},
			{
				id: 4,
				genericName: data[3]
			},
			{
				id: 5,
				genericName: data[4]
			}
		]);

		setLoading(false);
	};

	const addTask = async (task) => {
		const res = await fetch(process.env.REACT_APP_FLASK_WEBSERVER + "tasks", {
			/* TODO: Should I send request to tasks/user */ method: "POST",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(task)
		});
		//const data = await res.json() //don't need to await here
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const now = new Date();
		const task = {
			query: document.getElementById("verticalName").value,
			user: {
				uid: user.uid,
				name: user.displayName,
				email: user.email
			},
			status: "Pending",
			time: now.toLocaleString(),
			names: [document.getElementById("name1").value, document.getElementById("name2").value, document.getElementById("name3").value, document.getElementById("name4").value, document.getElementById("name5").value],
			autoSuggest: autoSuggest,
			numberOfGrants: parseInt(document.getElementById("numberOfGrants").value, 10),
			numberOfGrantsPerGenericName: parseInt(document.getElementById("numberOfGrantsPerGenericName").value, 10),
			OpportunityStatus: [document.getElementById("posted").checked === true && "posted", document.getElementById("forecasted").checked === true && "forecasted", document.getElementById("closed").checked === true && "closed"].filter(Boolean),
			numberOfRelevantPapers: parseInt(document.getElementById("numberOfRelevantPapers").value, 10),
			numberOfPatents: parseInt(numberOfPatents)
		};

		await addTask(task);
		setSubmitting(false);
		navigateOnSubmit();
	};

	const input_fields = ["Funding search request (1)", "Funding search request (2)", "Funding search request (3)", "Funding search request (4)", "Funding search request (5)"];
	return (
		<div style={{ width: "85%" }}>
			<form onSubmit={onSubmit}>
				<input
					className="input-text"
					id="verticalName"
					style={{ width: "100%", marginBottom: 0, boxSizing: "border-box" }}
					type="text"
					name="vertical"
					placeholder="Type Vertical Name"
					required
					onBlur={(e) => {
						autoSuggest && e.target.value.length > 5 && numberOfGrantsPerGenericName > 0 ? fetchGenericNames(e.target.value) : setGenericNames([]);
					}}
				/>
				<p style={{ color: "grey", fontSize: "12px", textAlign: "left", marginTop: 0, paddingBottom: "12px" }}>
					This name will be used as a direction to search and filter the most relevant content (Publications, Patents, Authors, Funding Calls)
				</p>

				<div style={{ display: "flex", flexDirection: "row", gap: "60px" }}>
					<div>
						<div style={{ display: "flex", flex: 1.8, flexDirection: "column", alignItems: "flex-start", gap: "20px", border: "2px solid #D0D0D0", padding: "15px", borderRadius: "8px" }}>
							<div>
								<input
									style={{ accentColor: "black" }}
									type="checkbox"
									id="autoSuggestCheckbox"
									checked={autoSuggest}
									onChange={(e) => {
										setAutoSuggest(e.currentTarget.checked);
										document.getElementById("verticalName").value.length > 5 &&
											numberOfGrantsPerGenericName > 0 &&
											document.getElementById("name1").value.length === 0 &&
											fetchGenericNames(document.getElementById("verticalName").value);
									}}
									disabled={loading}></input>
								<label style={{ fontSize: "13px" }}>AI Suggested Funding Search Requests (they help to find relevant funding opportunities)</label>
								<FontAwesomeIcon icon={faSpinner} spin size="xl" color="black" style={{ marginLeft: "20px", visibility: !loading && "hidden" }} />
							</div>

							{input_fields.map((placeholder, index) => (
								<input
									className="input-text"
									id={`name${index + 1}`}
									type="text"
									name={`name${index + 1}`}
									placeholder={placeholder}
									defaultValue={genericNames[index] !== undefined ? genericNames[index].genericName : ""}
									required={numberOfGrantsPerGenericName > 0}
									style={{ backgroundColor: autoSuggest ? "lightyellow" : "white", marginBottom: "0", marginTop: "px", padding: "8px 16px" }}
									disabled={autoSuggest ? true : false}
								/>
							))}
						</div>
						{submitting ? (
							<FontAwesomeIcon icon={faSpinner} style={{ color: "black", margin: "16px auto" }} spin size="2x"></FontAwesomeIcon>
						) : (
							<input className="input-submit" style={{ cursor: loading && "wait", padding: "8px 0", marginTop: "8px" }} type="submit" value="Submit" disabled={loading} />
						)}
					</div>

					<div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start", borderRadius: "12px", padding: "12px", backgroundColor: "#F0F0F0", fontSize: "14px" }}>
						<h3 style={{ fontSize: "18px", marginTop: "6px", marginBottom: "12px", fontWeight: "500" }}>Search Settings</h3>

						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
							<label style={{ textAlign: "left" }}># of patents:</label>
							<input
								className="input-number"
								id="numberOfPatents"
								type="number"
								value={numberOfPatents}
								min={10}
								max={20}
								onChange={(e) => {
									setNumberOfPatents(e.target.value);
								}}></input>
						</div>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
							<label style={{ textAlign: "left" }}># of relevant papers:</label>
							<input className="input-number" id="numberOfRelevantPapers" type="number" defaultValue="10" max={20} min={1}></input>
						</div>

						<h3 style={{ fontSize: "16px", marginTop: "6px", marginBottom: "12px", fontWeight: "500" }}>Funding:</h3>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
							<label style={{ flexGrow: 1, textAlign: "left" }}># of grants for vertical name:</label>
							<input className="input-number" id="numberOfGrants" type="number" defaultValue="5" max={10} min={1}></input>
						</div>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
							<label style={{ flexGrow: 1, textAlign: "left" }}># of grants for AI generated names:</label>
							<input
								className="input-number"
								id="numberOfGrantsPerGenericName"
								type="number"
								value={numberOfGrantsPerGenericName}
								max={5}
								min={0}
								onChange={(e) => {
									setNumberOfGrantsPerGenericName(e.target.value);
									autoSuggest &&
										document.getElementById("verticalName").value.length > 5 &&
										e.target.value > 0 &&
										document.getElementById("name1").value.length === 0 &&
										fetchGenericNames(document.getElementById("verticalName").value);
								}}></input>
						</div>
						<br></br>

						<div style={{ marginBottom: "8px", textAlign: "left" }}>
							<input style={{ accentColor: "black" }} type="checkbox" id="posted" defaultChecked></input>
							<label>Include Posted Grants</label>
						</div>

						<div style={{ marginBottom: "8px", textAlign: "left" }}>
							<input style={{ accentColor: "black" }} type="checkbox" id="forecasted" defaultChecked></input>
							<label>Include Forecasted Grants</label>
						</div>

						<div style={{ marginBottom: "8px", textAlign: "left" }}>
							<input style={{ accentColor: "black" }} type="checkbox" id="closed" disabled></input>
							<label>Include Closed Grants</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Form;
