import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

import DetailPage from "./DetailPage";

const EventPage = () => {
	const field_icon = {
		location: <LocationOnOutlinedIcon className="detail-icon-" style={{ color: "#FF6666" }} />,
		date: <CalendarMonthOutlinedIcon className="detail-icon-" />,
		start_time: (
			<>
				<AccessTimeOutlinedIcon className="detail-icon-" />
				<span>Start Time:&nbsp;</span>
			</>
		),
		end_time: (
			<>
				<AccessTimeOutlinedIcon className="detail-icon-" />
				<span>End Time:&nbsp;</span>
			</>
		),
		capacity: <GroupsOutlinedIcon className="detail-icon-" />,
		ticket_price: <AccountBalanceWalletOutlinedIcon className="detail-icon-" style={{ color: "#4C90F6" }} />
	};

	const field_heading = {
		overview: "Overview",
		agenda: "Agenda",
		speakers: "Speakers",
		sponsors: "Sponsors",
		venue_details: "Venue Details"
	};

	return (
		<DetailPage
			category={"event"}
			heading={"name"}
			field_icon={field_icon}
			importantDate={{ field: "registration_deadline", text: "Registration Deadline" }}
			externalLink={{ field: "registration_link", text: "Register for Event" }}
			field_heading={field_heading}
		/>
	);
};
export default EventPage;
