import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
	const navigate = useNavigate();

	return (
		<div className="back-button-2" onClick={() => navigate(-1)}>
			<ArrowBackIcon fontSize="small" />
			<span>&nbsp;Back</span>
		</div>
	);
};
export default BackButton;
