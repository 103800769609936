import List from "../../components/List/List"
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const EventsPage = () => {

    const icons = {
      'location':  <LocationOnOutlinedIcon style={{color: '#FF6666'}} className='detail-icon'/>,
      'date': <EventAvailableOutlinedIcon className='detail-icon'/>,
      'ticket_price': <AccountBalanceWalletOutlinedIcon style={{'color':'#4C90F6'}} className='detail-icon'/>
    };

  return (
    <List category={'events'} heading={'Events'} cardHeader={'EVENT'} icons={icons} dotColor={'red'}/>
  )

}

export default EventsPage