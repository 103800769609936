import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import auth from "../firebase";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ProfilePage = () => {
	const navigate = useNavigate();

	const [user, setUser] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [role, setRole] = useState("");
	const [loading, setLoading] = useState(false);

	const handleButtonClick = (url) => {
		window.open(url, "_blank");
	};

	const theme = useTheme();

	const buttonStyle = {
		textTransform: "none",
		marginTop: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		width: "40%",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	function splitName(fullName) {
		const nameParts = fullName.split(" ");
		const firstName = nameParts[0];
		const lastName = nameParts[nameParts.length - 1];
		const middleName = nameParts.slice(1, -1).join(" ");

		return {
			firstName,
			middleName,
			lastName
		};
	}

	useEffect(() => {
		document.title = "Profile - The Simple Platform";
		const getUser = async () => {
			const user = auth.currentUser;
			if (user) {
				setUser(user);

				const { firstName, middleName, lastName } = splitName(user.displayName || "");
				setFirstName(firstName);
				setMiddleName(middleName);
				setLastName(lastName);

				const userFromDb = await fetchUser(user.uid);

				if (userFromDb.role) {
					setRole(userFromDb.role);
				}
			}
		};
		getUser();
	}, []);

	const fetchUser = async (uid) => {
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_user?uid=" + uid;
		const res = await fetch(url);
		const user = await res.json();
		return user;
	};

	const submitForm = async (e) => {
		setLoading(true);
		e.preventDefault();

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_user?uid=" + user.uid;
		const newName = firstName + " " + middleName + " " + lastName;

		let data = {};
		if (user.displayName !== newName) {
			data["display_name"] = newName;
		}

		if (role !== "") {
			data["role"] = role;
		}

		if (Object.keys(data).length !== 0) {
			const res = await fetch(url, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			});

			if (res.status === 200) {
				toast.success("Profile updated successfully!", {
					className: "toast",
					autoClose: 3000
				});
			} else {
				toast.error("Unable to update profile", {
					className: "toast",
					autoClose: 3000
				});
			}
		}
		setLoading(false);
	};

	return (
		<div style={{ width: "75vw" }}>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<h2>My Profile</h2>
				<div>
					<Button
						variant="outlined"
						sx={{ textTransform: "none", margin: "0 10px" }}
						onClick={() => {
							handleButtonClick("https://www.termsfeed.com/blog/sample-terms-of-use-template/");
						}}>
						Terms of Use
					</Button>
					<Button
						variant="outlined"
						sx={{ textTransform: "none" }}
						onClick={() => {
							handleButtonClick("https://www.freeprivacypolicy.com/blog/privacy-policy-url/");
						}}>
						Privacy Policy
					</Button>
				</div>
			</div>

			<form onSubmit={submitForm} style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "400px" }}>
				<label className="label-style">First Name</label>
				<input className="input-style" type="text" id="firstname" name="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} />

				<label className="label-style">Middle Name</label>
				<input className="input-style" type="text" id="middlename" name="middlename" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />

				<label className="label-style">Last Name</label>
				<input className="input-style" type="text" id="lastname" name="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} />

				<label className="label-style">Email</label>
				<input className="input-style" type="text" id="email" name="email" disabled value={user.email} />

				<label className="label-style">Role</label>
				<select
					className="input-style"
					id="role"
					name="role"
					value={role}
					onChange={(e) => {
						setRole(e.target.value);
					}}>
					<option value="" disabled>
						{role !== "" ? role : "Select an option"}
					</option>
					<option value="Academic / Ph.D Candidate or Student">Academic / Ph.D Candidate or Student</option>
					<option value="Innovator">Innovator</option>
					<option value="Financier">Financier</option>
					<option value="Strategic Corporate">Strategic Corporate</option>
					<option value="Government/NGO">Government/NGO</option>
					<option value="Philanthropic">Philanthropic</option>
					<option value="Consultant">Consultant</option>
					<option value="Journalist">Journalist</option>
					<option value="Other">Other</option>
				</select>

				<div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
					{!loading ? (
						<Button type="submit" variant="outlined" disableRipple="true" sx={buttonStyle}>
							Save Changes
						</Button>
					) : (
						<FontAwesomeIcon icon={faSpinner} style={{ color: "black" }} spin size="2x"></FontAwesomeIcon>
					)}
				</div>
			</form>
		</div>
	);
};
export default ProfilePage;
