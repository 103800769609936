import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import "./Form.css";
import { useParams, useNavigate } from "react-router-dom";
import ImageUpload from "../ImageUpload";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const AddForm = ({ category, fields, user_uid }) => {
	useEffect(() => {
		document.title = "Add " + capitalizeFirstLetter(category) + " - The Simple Platform";
	}, []);

	const theme = useTheme();
	const navigate = useNavigate();

	const { vertical_id } = useParams();
	const [formData, setFormData] = useState({ vertical_id: vertical_id });

	const [submitting, setSubmitting] = useState(false);

	const buttonStyle = {
		textTransform: "none",
		margin: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		width: "30%",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		let newItem = {
			...formData
		};

		if (category === "tutorial") {
			newItem = {
				...formData,
				["creator_uid"]: user_uid
			};
		}

		const response = await addItem(newItem);
		if (response === 200) {
			toast.success(capitalizeFirstLetter(category) + " added successfully!", {
				className: "toast",
				autoClose: 3000
			});
			return navigate(-1);
		} else {
			toast.error("Unable to add " + category + "!", {
				className: "toast",
				autoClose: 3000
			});
		}
		setSubmitting(false);
	};

	const addItem = async (item) => {
		let url;
		if (category === "grant") {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "add_grant_manually";
		} else {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "add_" + category;
		}
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(item)
		});
		const data = await res.json();
		return res.status;
	};

	function capitalizeFirstLetter(string) {
		if (!string) return string;
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<form onSubmit={submitForm} className="add-form">
			<h2 className="heading-style">Add {capitalizeFirstLetter(category)}</h2>
			{fields.map((field) => (
				<div key={field.name} style={{ textAlign: "left" }}>
					<label className="label-style">
						{field.label}
						{field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
					</label>
					{field.drop_down === true ? (
						<select id={field.name} name={field.name} className="input-style" value={formData[field.name] || ""} onChange={handleChange} required={field.required} disabled={field.disabled || false}>
							<option value="" disabled>
								Select an option
							</option>
							{field.options.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					) : field.type === "image" ? (
						<ImageUpload />
					) : !field.text_area ? (
						<input
							type={field.type}
							id={field.name}
							name={field.name}
							className="input-style"
							placeholder={field.placeholder || ""}
							required={field.required}
							// value={data[field.name] || ''}
							onChange={handleChange}
							disabled={field.disabled || false}
						/>
					) : (
						<textarea
							id={field.name}
							name={field.name}
							className="input-style"
							rows={field.rows || "4"}
							// value={data[field.name] || ''}
							onChange={handleChange}
						/>
					)}
				</div>
			))}
			{!submitting ? (
				<div>
					<Button type="submit" variant="outlined" disableRipple={true} sx={buttonStyle}>
						Add {capitalizeFirstLetter(category)}
					</Button>
					<Button type="button" variant="outlined" disableRipple={true} onClick={() => navigate(-1)}>
						Cancel
					</Button>
				</div>
			) : (
				<FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ marginTop: "20px" }}></FontAwesomeIcon>
			)}
		</form>
	);
};
export default AddForm;
