import LogIn from '../components/LogIn'
import { useEffect } from 'react';

const LogInPage = ({ setEmail }) => {

  useEffect(() => {
    document.title = "The Simple Platform";
  }, [])

  return (
    <div className="form">
        <LogIn setEmail_={setEmail}/>
    </div>
  )
}
export default LogInPage