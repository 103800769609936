import EditForm from "../../components/Forms/EditForm";

const PublicationForm = () => {
	const category = "paper";
	const fields = [
		{ name: "title", label: "Title", type: "text", disabled: true, required: true },
		{ name: "venue", label: "Journal", type: "text" },
		{ name: "publicationDate", label: "Publication Date", type: "date" },
		{ name: "citationCount", label: "Citation Count", type: "number" },
		{ name: "influentialCitationCount", label: "Influential Citation Count", type: "number" },
		{ name: "referenceCount", label: "Reference Count", type: "number" },
		{ name: "tldr.text", label: "TLDR", type: "text", text_area: true, nested: true },
		{ name: "abstract", label: "Abstract", type: "text", text_area: true, rows: "12" },
		{ name: "openAccessPdf.url", label: "PDF Link", type: "url", nested: true }
	];

	return <EditForm category={category} fields={fields} />;
};
export default PublicationForm;
