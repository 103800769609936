import { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import auth from "../../firebase";
import { useParams } from "react-router-dom";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
	const [items, setItems] = useState();
	const [loading, setLoading] = useState(true);
	const [verticalCreator, setVerticalCreator] = useState();
	// const [tutorialCreator, setTutorialCreator] = useState(false);

	const user = auth.currentUser;
	const { vertical_id, query_id } = useParams();

	useEffect(() => {
		document.title = "The Simple Platform";
	}, []);

	useEffect(() => {
		document.title = "Home - The Simple Platform";
		const getItems = async () => {
			const items = await fetchItems();
			setItems(items);
			setVerticalCreator(items.verticalCreator);
			setLoading(false);
		};
		getItems();
	}, []);

	const fetchItems = async () => {
		const token = await user.getIdToken();

		let id;
		if (query_id) {
			id = query_id;
		} else {
			id = vertical_id;
		}

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_home_page" + "?vertical_id=" + id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data;
	};

	const publicationIcons = {
		authors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "green" }} />,
		venue: <BookOutlinedIcon className="detail-icon" />,
		publicationDate: <CalendarMonthOutlinedIcon className="detail-icon" />
	};

	const patentIcons = {
		inventors: <PeopleOutlinedIcon className="detail-icon" style={{ color: "green" }} />,
		publication_number: <BookOutlinedIcon className="detail-icon" />,
		publication_date: <CalendarMonthOutlinedIcon className="detail-icon" />
	};

	const grantIcons = {
		award_value: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />,
		AgencyName: <AccountBalanceOutlinedIcon style={{ color: "#AB6EF9" }} className="detail-icon" />,
		CloseDate: <EventAvailableOutlinedIcon className="detail-icon" />
	};

	const personIcons = {
		affiliations: <AccountBalanceOutlinedIcon style={{ color: "#AB6EF9" }} className="detail-icon" />
	};

	const personUrlIcons = {
		homepage: <img src={`${process.env.PUBLIC_URL}/Personal website.svg`} alt="X logo" className="detail-icon clickable" />,
		resume: <img src={`${process.env.PUBLIC_URL}/cv 2.svg`} alt="resume" className="detail-icon clickable" />,
		LinkedIn: <img src={`${process.env.PUBLIC_URL}/LinkedIn.svg`} alt="LinkedIn logo" className="detail-icon clickable" />,
		ResearchGate: <img src={`${process.env.PUBLIC_URL}/ResearchGate.svg`} alt="ResearchGate logo" className="detail-icon clickable" />,
		GoogleScholar: <img src={`${process.env.PUBLIC_URL}/People's social media.svg`} alt="GoogleScholar logo" className="detail-icon clickable" />,
		ORCID: <img src={`${process.env.PUBLIC_URL}/ORCID.svg`} alt="ORCID logo" className="detail-icon clickable" />,
		X: <img src={`${process.env.PUBLIC_URL}/X.svg`} alt="X logo" className="detail-icon clickable" />,
		organization_url: <InsertLinkOutlinedIcon className="detail-icon clickable" />
	};

	const jobIcons = {
		company: <BusinessOutlinedIcon className="detail-icon" />,
		location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />,
		salary: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />
	};

	const organizationIcons = {
		department: <AccountTreeOutlinedIcon className="detail-icon" />,
		location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />
	};

	const eventIcons = {
		location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />,
		date: <EventAvailableOutlinedIcon className="detail-icon" />,
		ticket_price: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />
	};

	const tutorialIcons = {
		author: <PersonOutlinedIcon style={{ color: "green" }} className="detail-icon" />,
		price: <AccountBalanceWalletOutlinedIcon style={{ color: "#4C90F6" }} className="detail-icon" />,
		published_date: <EventAvailableOutlinedIcon className="detail-icon" />
	};

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div style={{ width: "75%" }} className="papers">
			<h3 style={{ marginBottom: 0 }}>Welcome back, {user.displayName} &#128075;! </h3>
			<h4 style={{ marginBottom: 0 }}>Latest Updates:</h4>
			{items["papers"] && (
				<Card
					key={items["papers"]["_id"]}
					category={"papers"}
					details={items["papers"]}
					verticalCreator={verticalCreator}
					cardHeader={"LITERATURE"}
					icons={publicationIcons}
					dotColor={"orange"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["patents"] && (
				<Card
					key={items["patents"]["_id"]}
					category={"patents"}
					details={items["patents"]}
					verticalCreator={verticalCreator}
					cardHeader={"PATENT"}
					icons={patentIcons}
					dotColor={"orange"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["grants"] && (
				<Card
					key={items["grants"]["_id"]}
					category={"grants"}
					details={items["grants"]}
					verticalCreator={verticalCreator}
					cardHeader={"GRANT"}
					icons={grantIcons}
					dotColor={"#4C90F6"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["authors"] && (
				<Card
					key={items["authors"]["_id"]}
					category={"people"}
					details={items["authors"]}
					verticalCreator={verticalCreator}
					cardHeader={"AUTHOR"}
					icons={personIcons}
					urlIcons={personUrlIcons}
					dotColor={"green"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["inventors"] && (
				<Card
					key={items["inventors"]["_id"]}
					category={"people"}
					details={items["inventors"]}
					verticalCreator={verticalCreator}
					cardHeader={"INVENTOR"}
					icons={personIcons}
					urlIcons={personUrlIcons}
					dotColor={"green"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["people"] && (
				<Card
					key={items["people"]["_id"]}
					category={"people"}
					details={items["people"]}
					verticalCreator={verticalCreator}
					cardHeader={items["people"]["type"].toUpperCase()}
					// cardHeader={"PERSON"}
					icons={personIcons}
					urlIcons={personUrlIcons}
					dotColor={"green"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["jobs"] && (
				<Card key={items["jobs"]["_id"]} category={"jobs"} details={items["jobs"]} verticalCreator={verticalCreator} cardHeader={"JOB"} icons={jobIcons} dotColor={"black"} vertical_id={vertical_id} query_id={query_id} sublist={true} />
			)}
			{items["organizations"] && (
				<Card
					key={items["organizations"]["_id"]}
					category={"organizations"}
					details={items["organizations"]}
					verticalCreator={verticalCreator}
					cardHeader={"ORGANIZATION"}
					icons={organizationIcons}
					dotColor={"#AB6EF9"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{items["events"] && (
				<Card
					key={items["events"]["_id"]}
					category={"events"}
					details={items["events"]}
					verticalCreator={verticalCreator}
					cardHeader={"EVENT"}
					icons={eventIcons}
					dotColor={"#FF6666"}
					vertical_id={vertical_id}
					query_id={query_id}
					sublist={true}
				/>
			)}
			{/* {items["tutorials"] && (
				<Card key={items["tutorials"]["_id"]} category={"tutorials"} details={items["tutorials"]} verticalCreator={tutorialCreator} cardHeader={"TUTORIAL"} icons={tutorialIcons} dotColor={"pink"} vertical_id={vertical_id} />
			)} */}
		</div>
	);
};

export default HomePage;
