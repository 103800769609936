import List from "../../components/List/List"
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const JobsPage = () => {

    const icons = {
        'company': <BusinessOutlinedIcon className='detail-icon'/>,
        'location': <LocationOnOutlinedIcon style={{color: '#FF6666'}} className='detail-icon'/>,
        'salary': <AccountBalanceWalletOutlinedIcon style={{'color':'#4C90F6'}} className='detail-icon'/>,
        
    };

  return (
    <List category={'jobs'} heading={'Jobs'} cardHeader={'JOB'} icons={icons} dotColor={'black'}/>
  )

}

export default JobsPage