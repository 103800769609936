import List from "../../components/List/List"
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const PatentsPage = () => {
  const icons = {
    'inventors': <PeopleOutlinedIcon className='detail-icon' style={{color: 'green'}}/>,
    'publication_number': <BookOutlinedIcon className='detail-icon'/>,
    'publication_date': <CalendarMonthOutlinedIcon className='detail-icon'/>
  };

  return (
    <List category={'patents'} heading={'Patents'} cardHeader={'PATENT'} icons={icons} dotColor={'orange'}/>
  )
}
export default PatentsPage