import List from "../../components/List/List";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const OrganizationsPage = () => {
	const icons = {
		department: <AccountTreeOutlinedIcon className="detail-icon" />,
		location: <LocationOnOutlinedIcon style={{ color: "#FF6666" }} className="detail-icon" />
	};

	return <List category={"organizations"} heading={"Organizations"} cardHeader={"ORGANIZATION"} icons={icons} dotColor={"purple"} />;
};

export default OrganizationsPage;

// projection = {'title': 1, 'author': 1, 'tutorial_link': 1, 'published_date': 1, 'price': 1, 'show': 1}
