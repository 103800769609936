import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Form from "../components/Form";
import Tasks from "../components/Tasks";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const TryRNAIPage = ({ userRole }) => {
	const theme = useTheme();
	const [activeButton, setActiveButton] = useState("Try RNAI");

	useEffect(() => {
		document.title = "RNAI - The Simple Platform";
	}, []);

	const handleClick = (button) => {
		if (button == "Try RNAI") {
			setActiveButton("Try RNAI");
		} else {
			setActiveButton("My Requests");
		}
	};

	const navigateOnSubmit = () => {
		setActiveButton("My Requests");
	};

	const activeButtonStyle = {
		textTransform: "none",
		margin: "10px 10px 10px 0px",
		color: theme.palette.primary.main,
		fontWeight: "bold",
		borderBottom: "2px solid",
		borderColor: "primary.main",
		borderRadius: 0,
		paddingBottom: "4px"
	};
	const buttonStyle = {
		textTransform: "none",
		margin: "10px 10px 10px 0px",
		color: "black"
	};

	return (
		<>
			<div style={{ textAlign: "left", width: "85%", margin: "0 auto", marginBottom: "12px", marginTop: "12px" }}>
				<Button variant="text" disableRipple={true} sx={activeButton === "Try RNAI" ? activeButtonStyle : buttonStyle} onClick={() => handleClick("Try RNAI")}>
					Try RNAI
				</Button>
				<Button variant="text" disableRipple={true} sx={activeButton === "My Requests" ? activeButtonStyle : buttonStyle} onClick={() => handleClick("My Requests")}>
					My Queries
				</Button>
			</div>

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "80vw", textAlign: "center" }}>{activeButton === "Try RNAI" ? <Form navigateOnSubmit={navigateOnSubmit} /> : <Tasks userRole={userRole} />}</div>
		</>
	);
};
export default TryRNAIPage;
