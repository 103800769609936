import List from "../../components/List/List"
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const PublicationsPage = ({ query = false}) => {
  
  const icons = {
    'authors': <PeopleOutlinedIcon className='detail-icon' style={{color: 'green'}}/>,
    'venue': <BookOutlinedIcon className='detail-icon'/>,
    'publicationDate': <CalendarMonthOutlinedIcon className='detail-icon'/>
  };

  return (
    <List category={'papers'} heading={'Publications'} cardHeader={'LITERATURE'} icons={icons} dotColor={'orange'} query={query}/>
  )
}
export default PublicationsPage