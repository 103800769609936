import FormWithImage from "../../components/Forms/FormWithImage";

const PersonForm = ({ functionality }) => {
	const category = "person";
	const fields = [
		{ name: "name", label: "Name", type: "text", required: true },
		{
			name: "type",
			label: "Type",
			type: "text",
			required: true,
			drop_down: true,
			options: [
				{ value: "Academic / Ph.D Candidate or Student", label: "Academic / Ph.D Candidate or Student" },
				{ value: "Innovator", label: "Innovator" },
				{ value: "Financier", label: "Financier" },
				{ value: "Strategic Corporate", label: "Strategic Corporate" },
				{ value: "Government/NGO", label: "Government/NGO" },
				{ value: "Philanthropic", label: "Philanthropic" },
				{ value: "Consultant", label: "Consultant" },
				{ value: "Journalist", label: "Journalist" },
				{ value: "Other", label: "Other" }
			]
		},
		{ name: "affiliations", label: "Affiliations", type: "text" },
		{ name: "department", label: "Department", type: "text" },
		{ name: "program", label: "Program", type: "text" },
		{ name: "about", label: "About", type: "text", text_area: true },
		{ name: "homepage", label: "Homepage URL", type: "url", placeholder: "https://profiles.stanford.edu/jackson" },
		{ name: "email", label: "Email", type: "email", placeholder: "Rob.Jackson@stanford.edu" },
		{ name: "resume", label: "Resume", type: "url", placeholder: "https://cap.stanford.edu/profiles/viewCV?facultyId=48949&name=Rob_Jackson" },
		{ name: "LinkedIn", label: "LinkedIn", type: "url", placeholder: "https://www.linkedin.com/in/rob-jackson/" },
		{ name: "ResearchGate", label: "ResearchGate", type: "url", placeholder: "https://www.researchgate.net/profile/Robert-Jackson-14" },
		{ name: "GoogleScholar", label: "Google Scholar", type: "url", placeholder: "https://scholar.google.com/citations?user=G3NBUzwAAAAJ&hl=en" },
		{ name: "ORCID", label: "ORCID", type: "url", placeholder: "https://orcid.org/0000-0001-8846-7147" },
		{ name: "X", label: "X (formerly Twitter)", type: "url", placeholder: "https://twitter.com/robajackson?lang=en" },
		{ name: "organization_url", label: "Organization URL", type: "url", placeholder: "https://jacksonlab.stanford.edu/" },
		{ name: "image_url", label: "Image", type: "image", required: false }
	];

	return <FormWithImage category={category} fields={fields} functionality={functionality} />;
};
export default PersonForm;
