import List from "../../components/List/List"
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

const FundingPage = () => {

    const icons = {
        'award_value': <AccountBalanceWalletOutlinedIcon style={{'color':'#4C90F6'}} className='detail-icon'/>,
        'AgencyName': <AccountBalanceOutlinedIcon style={{'color':'#AB6EF9'}} className='detail-icon'/>,
        'CloseDate': <EventAvailableOutlinedIcon className='detail-icon'/>
    };

  return (
    <List category={'grants'} heading={'Funding'} cardHeader={'GRANT'} icons={icons} dotColor={'#4C90F6'}/>
  )

}

export default FundingPage
