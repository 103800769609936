import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

const CreateCredentialsPage = ({ role }) => {
	const theme = useTheme();

	const [submitting, setSubmitting] = useState(false);
	const [formData, setFormData] = useState();
	const [api_key, setApi_key] = useState("");

	const buttonStyle = {
		textTransform: "none",
		margin: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		width: "30%",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const response = await createCredentials(formData);
		if (response.status === 200) {
			toast.success("Credentials created successfully!", {
				className: "toast",
				autoClose: 3000
			});
			// return navigate(-1);
		} else if (response.status === 400) {
			toast.error("Unable to create credentials! " + response.data.message, {
				className: "toast",
				autoClose: 3000
			});
		} else {
			toast.error("Unable to create credentials! " + response.data.message, {
				className: "toast",
				autoClose: 3000
			});
		}

		setSubmitting(false);
	};

	const createCredentials = async (formData) => {
		let url;
		if (role === "Vertical Editor") {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "createVerticalEditor";
		} else {
			url = process.env.REACT_APP_FLASK_WEBSERVER + "createUser";
		}

		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-API-KEY": api_key
			},
			body: JSON.stringify(formData)
		});
		const data = await res.json();

		return {
			status: res.status,
			data: data
		};
	};

	useEffect(() => {
		document.title = "Create " + role + " - The Simple Platform";
	}, []);

	return (
		<div>
			<form onSubmit={submitForm} className="add-form">
				<h2 className="heading-style">Create A {role} Account</h2>

				<div style={{ textAlign: "left" }}>
					<div>
						<label className="label-style">
							<span>Email</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input type="email" id="email" name="email" className="input-style" placeholder={role === "Vertical Editor" ? "paul_ve@rnai.com" : "paul_user@rnai.com"} required="true" autoComplete="new-email" onChange={handleChange} />
					</div>

					<div>
						<label className="label-style">
							<span>Password</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input type="password" id="password" name="password" className="input-style" required="true" autoComplete="new-password" onChange={handleChange} />
					</div>

					<div>
						<label className="label-style">
							<span>Name</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input type="text" id="display_name" name="display_name" className="input-style" placeholder="Paul Galleberg" required="true" onChange={handleChange} />
					</div>

					{role === "User" && (
						<div>
							<label className="label-style">
								<span>Vertical ID To Join</span>
								<span style={{ color: "red" }}>&nbsp;*</span>
							</label>
							<input type="text" id="vertical_joined" name="vertical_joined" className="input-style" placeholder="66ab5f01a3a16ad7a481b5a8" required="true" onChange={handleChange} />
						</div>
					)}

					<div>
						<label className="label-style">
							<span>API Key</span>
							<span style={{ color: "red" }}>&nbsp;*</span>
						</label>
						<input
							type="text"
							id="api_key"
							name="api_key"
							className="input-style"
							required="true"
							onChange={(e) => {
								setApi_key(e.target.value);
							}}
						/>
					</div>
				</div>

				{!submitting ? (
					<div>
						<Button type="submit" variant="outlined" disableRipple="true" sx={buttonStyle}>
							Create Credentials
						</Button>
						<Button type="reset" variant="outlined" disableRipple={true} onClick={() => {}}>
							Reset
						</Button>
					</div>
				) : (
					<FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ marginTop: "20px" }}></FontAwesomeIcon>
				)}
			</form>
		</div>
	);
};
export default CreateCredentialsPage;
