import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import "./Form.css";
import { useParams, useNavigate } from "react-router-dom";
import ImageUpload from "../ImageUpload";
import { toast } from "react-toastify";
import auth from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const EditForm = ({ category, fields }) => {
	const user = auth.currentUser;

	const theme = useTheme();
	const navigate = useNavigate();

	const { vertical_id, id } = useParams();
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState({});

	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileSelect = (file) => {
		setSelectedFile(file);
		setData["image_url"] = file;
	};

	const buttonStyle = {
		textTransform: "none",
		margin: "10px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		width: "30%",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
			borderColor: theme.palette.primary.dark
		}
	};

	useEffect(() => {
		document.title = "Edit " + capitalizeFirstLetter(category) + " - The Simple Platform";
		const getItem = async () => {
			const item = await fetchItem();
			setData(item);
			setLoading(false);
		};
		getItem();
	}, []);

	const fetchItem = async () => {
		const token = await user.getIdToken();

		const url = process.env.REACT_APP_FLASK_WEBSERVER + "get_" + category + "_details?id=" + id + "&vertical_id=" + vertical_id;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		const data = await res.json();
		return data.data;
	};

	const submitForm = async (e) => {
		e.preventDefault();
		setSubmitting(true);

		const newItem = {
			...data,
			["image_url"]: selectedFile
		};

		const response = await editItem(newItem);
		if (response === 200) {
			toast.success(capitalizeFirstLetter(category) + " edited successfully!", {
				className: "toast",
				autoClose: 3000
			});
			return navigate(-1);
		} else {
			toast.error("Unable to edit " + category + "!", {
				className: "toast",
				autoClose: 3000
			});
		}
		setSubmitting(false);
	};

	const editItem = async (item) => {
		const url = process.env.REACT_APP_FLASK_WEBSERVER + "edit_" + category + "?id=" + item._id;
		const res = await fetch(url, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(item)
		});
		await res.json();
		return res.status;
	};

	// const handleChange = (e) => {
	//     const { name, value } = e.target;
	//     setData({
	//         ...data,
	//         [name]: value,
	//     });
	// };

	const handleChange = (e) => {
		const { name, value } = e.target;
		setData((prevData) => {
			const keys = name.split("."); // Split the name by the dot notation
			const lastKey = keys.pop();
			const nestedData = { ...prevData };

			let current = nestedData;
			keys.forEach((key) => {
				if (!current[key]) {
					current[key] = {}; // Initialize the object if it does not exist
				}
				current = current[key];
			});

			current[lastKey] = value; // Set the value to the last key

			return nestedData;
		});
	};

	function capitalizeFirstLetter(string) {
		if (!string) return string;
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	function getNestedProperty(obj, propertyPath) {
		const properties = propertyPath.split(".");
		return properties.reduce((prev, curr) => (prev && prev[curr] !== undefined ? prev[curr] : undefined), obj);
	}

	return loading ? (
		<div className="container">
			<FontAwesomeIcon icon={faSpinner} spin size="10x" color="whitesmoke"></FontAwesomeIcon>
		</div>
	) : (
		<div>
			<form onSubmit={submitForm} className="add-form">
				<h2 className="heading-style">Edit {capitalizeFirstLetter(category)}</h2>
				{fields.map((field) => (
					<div key={field.name} style={{ textAlign: "left" }}>
						<label className="label-style">
							{field.label}
							{field.required && <span style={{ color: "red" }}>&nbsp;*</span>}
						</label>
						{field.drop_down === true ? (
							<select id={field.name} name={field.name} className="input-style" value={data[field.name] || ""} onChange={handleChange} required={field.required} disabled={field.disabled || false}>
								<option value="" disabled>
									{field.placeholder || "Select an option"}
								</option>
								{field.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						) : field.type === "image" ? (
							<ImageUpload existingImageUrl={data[field.name] || ""} onFileSelect={handleFileSelect} />
						) : !field.text_area ? (
							<input
								type={field.type}
								id={field.name}
								name={field.name}
								className="input-style"
								placeholder={field.placeholder || ""}
								required={field.required}
								value={!field.nested ? data[field.name] : getNestedProperty(data, field.name) || ""}
								onChange={handleChange}
								disabled={field.disabled || false}
							/>
						) : (
							<textarea type={field.type} id={field.name} name={field.name} className="input-style" rows={field.rows || "4"} value={!field.nested ? data[field.name] : getNestedProperty(data, field.name) || ""} onChange={handleChange} />
						)}
					</div>
				))}

				{!submitting ? (
					<div>
						<Button type="submit" variant="outlined" disableRipple="true" sx={buttonStyle}>
							{/* Edit {capitalizeFirstLetter(category)} */}
							Save Edits
						</Button>
						<Button type="button" variant="outlined" disableRipple={true} onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</div>
				) : (
					<FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ marginTop: "20px" }}></FontAwesomeIcon>
				)}
			</form>
		</div>
	);
};
export default EditForm;
