import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck, faTimes, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import AlertDialog from "./AlertDialog";

const Task = ({ task, admin, onDelete, userRole }) => {
	const { vertical_id, query_id } = useParams();
	let link;
	if (userRole === "admin") {
		link = `/ve/vertical/${task._id}/home`;
	} else {
		link = `/u/vertical/${vertical_id}/query/${task._id}/home`;
	}

	return (
		<>
			<span className="result-item">{task.query}</span>
			{admin && <span className="result-item">{task.user.name}</span>}
			<span className="result-item">{task.time}</span>

			<div className="result-item">
				{task.status === "Completed" ? (
					<Link to={link} style={{ color: "#1976d2", display: "flex", justifyContent: "space-evenly" }}>
						<span>{task.status}</span>
						<span>{<FontAwesomeIcon icon={faCheck} size="xs" />}</span>
					</Link>
				) : (
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<span>{task.status}</span>
						<span>{<FontAwesomeIcon icon={faSpinner} spin size="xs" />}</span>
					</div>
				)}
			</div>

			<span className="result-item">
				<AlertDialog id={task._id} action={onDelete} dialogTitle={"Delete vertical: " + task.query + "?"} dialogContentText={"This action cannot be undone. This will permanently delete the vertical and remove the data from our servers."} />
			</span>
		</>
	);
};

export default Task;
