import AddForm from "../../components/Forms/AddForm";
import EditForm from "../../components/Forms/EditForm";

const EventForm = ({ functionality }) => {
	const category = "event";
	const fields = [
		{ name: "name", label: "Name", type: "text", required: true },
		{ name: "location", label: "Location", type: "text", required: true },
		{ name: "date", label: "Date", type: "date", required: true },
		{ name: "start_time", label: "Start Time", type: "time", required: true },
		{ name: "end_time", label: "End Time", type: "time", required: false },
		{ name: "capacity", label: "Capacity", type: "number", required: false },
		{ name: "ticket_price", label: "Ticket Price", type: "text", required: false, placeholder: "$120" },
		{ name: "registration_deadline", label: "Registration Deadline", type: "date", required: false },
		{ name: "registration_link", label: "Registration Link", type: "url", required: true, placeholder: "https://www.eventbrite.com/e/unlocking-leadership-support" },
		{ name: "overview", label: "Overview", type: "text", required: true, text_area: true },
		{ name: "agenda", label: "Agenda", type: "text", required: false, text_area: true },
		{ name: "speakers", label: "Speakers", type: "text", required: false, text_area: true },
		{ name: "sponsors", label: "Sponsors", type: "text", required: false, text_area: true },
		{ name: "venue_details", label: "Venue Details", type: "text", required: false, text_area: true }
	];

	return functionality === "add" ? <AddForm category={category} fields={fields} /> : <EditForm category={category} fields={fields} />;
};
export default EventForm;
