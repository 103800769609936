import EditForm from "../../components/Forms/EditForm";

const PatentForm = () => {
	const category = "patent";
	const fields = [
		{ name: "title", label: "Title", type: "text", disabled: true, required: true },
		{ name: "publication_number", label: "Publication Number", type: "text", disabled: true, required: true },
		{ name: "application_number", label: "Application Number", type: "text" },
		{ name: "publication_date", label: "Publication Date", type: "date" },
		{ name: "priority_date", label: "Priority Date", type: "date" },
		{ name: "filing_date", label: "Filing Date", type: "date" },
		{ name: "grant_date", label: "Grant Date", type: "date" },
		{ name: "country", label: "Country", type: "text" },
		{ name: "abstract", label: "Abstract", type: "text", text_area: true },
		{ name: "pdf", label: "PDF Link", type: "url" }
	];

	return <EditForm category={category} fields={fields} />;
};
export default PatentForm;
