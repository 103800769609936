import AddForm from "../../components/Forms/AddForm";
import EditForm from "../../components/Forms/EditForm";

const JobForm = ({ functionality }) => {
	const category = "job";
	const fields = [
		{ name: "title", label: "Title", type: "text", required: true },
		{ name: "company", label: "Company", type: "text", required: true },
		{ name: "location", label: "Location", type: "text", required: true },
		{ name: "salary", label: "Salary", type: "text", required: false, placeholder: "$120,000 - $150,000" },
		{ name: "posting_date", label: "Posting Date", type: "date", required: false },
		{
			name: "workplace_type",
			label: "Workplace Type",
			type: "text",
			drop_down: true,
			options: [
				{ value: "On-site", label: "On-site" },
				{ value: "Hybrid", label: "Hybrid" },
				{ value: "Remote", label: "Remote" }
			]
		},
		{
			name: "job_type",
			label: "Job Type",
			type: "text",
			required: true,
			drop_down: true,
			options: [
				{ value: "Full-time", label: "Full-time" },
				{ value: "Part-time", label: "Part-time" },
				{ value: "Internship", label: "Internship" },
				{ value: "Contract", label: "Contract" },
				{ value: "Temporary", label: "Temporary" },
				{ value: "Volunteer", label: "Volunteer" }
			]
		},
		{ name: "contact_information", label: "Contact Information", type: "text", required: false },
		{ name: "application_deadline", label: "Application Deadline", type: "date", required: false },
		{ name: "application_link", label: "Application Link", type: "url", required: true, placeholder: "https://www.amazon.jobs/en/" },
		{ name: "description", label: "Description", type: "text", required: true, text_area: true, rows: "12" },
		{ name: "responsibilities", label: "Responsibilities", type: "text", required: false, text_area: true, rows: "12" },
		{ name: "requirements", label: "Requirements", type: "text", required: false, text_area: true, rows: "8" },
		{ name: "preferred_qualifications", label: "Preferred Qualifications", type: "text", required: false, text_area: true, rows: "8" },
		{ name: "benefits", label: "Benefits", type: "text", required: false, text_area: true, rows: "8" },
		{ name: "company_overview", label: "Company Overview", type: "text", required: false, text_area: true, rows: "4" },
		{ name: "how_to_apply", label: "How To Apply", type: "text", required: false, text_area: true, rows: "4" }
	];

	return functionality === "add" ? <AddForm category={category} fields={fields} /> : <EditForm category={category} fields={fields} />;
};
export default JobForm;
