import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<>
			<img
				onClick={() => {
					navigate("/");
				}}
				src={`${process.env.PUBLIC_URL}/logo.svg`}
				className="img"
				alt="The Simple Platform"
				style={{ marginTop: "100px" }}
			/>
		</>
	);
};

export default Header;
